export const enum MessageEnum {
    /**
     * 订单类消息
     */
    Order = 'order',

    /**
     * 单酒店系统消息
     */
    SingleHotelSystem = 'single_hotel_system'
}

/**
 * 单店系统消息
 */
export const enum SingleHotelSystemTypeEnum {
    /**
     * OTA 酒店账单驳回消息
     */
    OTA_BILL_REJECT = 'OTA_BILL_REJECT',
    /**
     * OTA 酒店开票驳回消息
     */
    OTA_WAITING_BILL_REJECT_MSG = 'OTA_WAITING_BILL_REJECT_MSG',
    /**
     * OTA 酒店账单打款消息
     */
    OTA_BILL_PAY = 'OTA_BILL_PAY'
}

/**
 * 订单消息子类
 */
export const enum OrderMessageTypeEnum {
    /**
     * 服务订单开票成功
     */
    INVOICE_SUCCESS = 'INVOICE_SUCCESS',

    /**
     * OTA 订单提醒
     */
    OTA_ORDER_MESSAGE = 'OTA_ORDER_MESSAGE'
}
