<template>
    <a-modal
        v-model:visible="visible"
        :width="600"
        :body-style="{ padding: '30px 24px' }"
        title="“抢单宝”为您推送了一个订单"
        centered
        :mask-closable="false"
        :after-close="close"
        :footer="null"
        :z-index="10"
        :keyboard="false"
    >
        <a-spin class="spin" :spinning="loading" :delay="500" tip="加载中...">
            <div class="body">
                <div class="price-container">
                    <span v-if="!data.invoiceFlag" class="tag">无需开票</span>
                    <span v-if="PayTypeCodeMap[data.payTypeCode]" class="tag">{{ PayTypeCodeMap[data.payTypeCode] }}</span>
                    <span>价格</span>
                    <span class="price">&nbsp;¥{{ data.orderPrice }}</span>
                    <span class="contain">{{ data.breakfast ? '含早' : '不含早' }}</span>
                    <price-popover :id="id" :get-popup-container="getPopupContainer" />
                </div>
                <div class="info-container">
                    <div class="row common-flex">
                        <span class="label">客人姓名</span>
                        <span class="value">{{ data.contactName }}</span>
                    </div>
                    <div class="row common-flex">
                        <span class="label">住宿日期</span>
                        <span class="value">{{ data.checkinDate }}-{{ data.checkoutDate }}&nbsp;&nbsp;{{ data.interval }}{{ data.hourFlag ? '小时' : '晚' }}</span>
                        <img v-if="getIcon(data.tagType)" class="icon" :src="getIcon(data.tagType)" />
                    </div>
                    <div class="row common-flex">
                        <span class="label">房型名称</span>
                        <span class="value">{{ data.productName }}&nbsp;{{ data.roomNum }}间</span>
                    </div>
                    <div class="row common-flex">
                        <span class="label">备注</span>
                        <span class="value">{{ data.memo }}</span>
                    </div>
                </div>
            </div>
            <div class="footer common-flex">
                <div class="common-flex overTime">请在<a-statistic-countdown format="mm:ss" :value="data.overTime" />内确认，逾期将自动拒单 </div>
                <div class="common-flex rejectTime">
                    <a-button v-if="rejectTimeBool" type="primary" ghost disabled class="common-flex">
                        拒单 (<a-statistic-countdown format="mm:ss" :value="data.rejectTime" @finish="rejectTimeFinish" />)
                    </a-button>
                    <grad-order-reject v-else :id="id" type="modal" :outer-dict="dict" :is-test-order="data.isTested" @onOk="close">
                        <template #button>
                            <a-button type="primary" ghost class="normal"> 拒单 </a-button>
                        </template>
                    </grad-order-reject>
                    <a-button type="primary" :loading="confirmLoading" @click="submit">接单</a-button>
                </div>
            </div>
        </a-spin>
    </a-modal>
</template>

<script lang="ts">
import { reactive, toRefs, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { OtaOrderStatus } from '@/web-socket/ota/interfaces'
import { useModal } from '@/hooks/useModal'
import GradOrderReject from './GradOrderReject.vue'
import { setGradOrderModalVisible } from './grad-modal-visible'
import { PayTypeCodeMap } from '@/business-components/constants'
import PricePopover from '../price-popover/Index.vue'
import yuanqi from '@/assets/icons/yuanqi.png'
import jinriyudi from '@/assets/icons/jinriyudi.png'
import mingriyudi from '@/assets/icons/mingriyudi.png'
import { confirmOrder, getRejectReason } from '@/http/api/channel/channel-order'
import PreCheckMessageModal from '@/business-components/platoon-room-modal/PreCheckMessageModal.vue'

interface Props {
    data: any
    remove: () => void
    onOk: () => void
}

export default {
    components: {
        GradOrderReject,
        PricePopover
    },
    props: {
        remove: Function,
        onOk: Function,
        data: {
            type: Object,
            required: true
        }
    },
    setup(props: Props) {
        const state = reactive({
            visible: true,
            loading: false,
            confirmLoading: false,
            dict: [],
            rejectTimeBool: true
        })

        const close = () => {
            state.visible = false
            setGradOrderModalVisible(false)
        }

        onMounted(() => {
            getRejectReason().then((res) => {
                state.dict = res || []
            })
            if (props.data?.nowTime >= props.data?.rejectTime) state.rejectTimeBool = false
        })

        const submit = async () => {
            try {
                state.confirmLoading = true
                const res = await confirmOrder(props.data.id, false, [])
                if (res.code === 0) {
                    props.onOk?.()
                    useModal(
                        PreCheckMessageModal,
                        {
                            msg: '请确保能正常安排客人入住，若无法原单安排，将会产生赔付。',
                            okBtnText: '我已知晓'
                        },
                        () => {
                            state.visible = false
                        }
                    )
                } else {
                    message.error(res.message)
                }
            } finally {
                state.confirmLoading = false
            }
        }

        const rejectTimeFinish = () => {
            state.rejectTimeBool = false
        }

        const IconMap = {
            1: jinriyudi,
            2: mingriyudi,
            3: yuanqi
        }
        const getIcon = (val: string) => IconMap[val]

        return {
            OtaOrderStatus,
            close,
            submit,
            id: props.data.id,
            rejectTimeFinish,
            PayTypeCodeMap,
            getPopupContainer: (el) => el.parentElement || document.body,
            getIcon,
            ...toRefs(state)
        }
    }
}
</script>

<style lang="scss" scoped>
.ant-spin-nested-loading {
    color: #333;

    p {
        margin-bottom: 0;
    }

    .body {
        position: relative;
        .price-container {
            position: absolute;
            right: 0;
            top: 0;

            .tag {
                padding: 0 7px;
                line-height: 1;
                color: #f16d4a;
                margin-right: 6px;
                border-radius: 2px;
                border: 1px solid #f16d4a;
            }

            .price {
                margin-top: 4px;
                color: #f16d4a;
                padding: 0 6px;
                border-radius: 2px;
                font-size: 16px;
            }

            .contain {
                color: #f16d4a;
            }

            :deep(.ant-popover) {
                top: 20px !important;
            }

            :deep(.ant-popover-inner-content) {
                padding: 0;
            }

            :deep(.ant-btn) {
                min-width: 0 !important;
            }
        }

        .info-container {
            .row {
                margin-bottom: 6px;
                align-items: flex-start;

                .label {
                    flex: 0 0 56px;
                    color: #666;
                    text-align: right;
                    margin-right: 12px;
                }

                .value {
                    word-break: break-all;
                }

                .icon {
                    height: 18px;
                    margin: 3px 0 0 6px;
                }
            }
        }
    }

    .footer {
        // padding-top: 10px;
        justify-content: space-between;

        .ant-btn {
            width: 120px;
            height: 34px;
            margin-left: 20px;
        }

        :deep(.ant-statistic-content) {
            font-family: none;
            font-size: inherit;
        }

        .overTime {
            :deep(.ant-statistic-content) {
                color: #f16d4a;
                min-width: 45px;
                text-align: center;
            }
        }

        .rejectTime {
            :deep(.ant-statistic-content) {
                color: $primary-color;
            }

            :deep(.ant-btn-background-ghost) {
                color: $primary-color;
                justify-content: center;
                background-color: #e8e7e7 !important;
            }

            :deep(.ant-btn-background-ghost.normal) {
                background-color: #fff !important;
            }
        }

        :deep(.ant-popover) {
            box-shadow: 0 2px 4px 0 #4ac3bb;
            border: 1px solid #4ac3bb;
            padding-bottom: 0;
        }

        .common-ant-radio-group-normal {
            :deep(.ant-radio-wrapper span:last-child) {
                line-height: 20px;
            }
        }

        :deep(.ant-popover-content .ant-popover-arrow) {
            display: none;
        }
    }
}
</style>
