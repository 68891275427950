import { createStorage as create } from './storage'
import { enableStorageEncryption } from '@/config/encryptionSetting'

const createOptions = (storage?: Storage, hasEncrypt?: boolean) => {
    return {
        hasEncrypt,
        storage
    }
}

export const Storage = create(createOptions())

export const createStorage = (storage: Storage = localStorage, hasEncrypt: boolean = enableStorageEncryption) => {
    return create(createOptions(storage, hasEncrypt))!
}

export default Storage
