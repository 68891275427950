import { VNode } from 'vue'
import { NotificationArgsProps } from 'ant-design-vue/lib/notification'
import { speckText } from '@/utils/tts'
import { notification } from 'ant-design-vue'

export const notifyMessage = async (playText: string, message: string, desc: string | VNode, options: Omit<NotificationArgsProps, 'message' | 'description'> = Object.create(null), speck = true) => {
    if (speck) {
        await speckText(playText)
    }
    notification.config({
        top: '55px'
    })
    await notification.open({
        class: 'socket-message-notification',
        message: message,
        description: desc,
        ...options,
        duration: null
    })
}
