<template>
    <a-modal class="modal" v-model:visible="visible" title="拒绝取消" :width="null" :bodyStyle="{ minWidth: '500px' }" centered :after-close="remove" :footer="null">
        <a-form ref="formRef" class="form" :model="formData" :rules="formRules">
            <a-form-item label="拒绝取消原因" name="refuseReason">
                <a-textarea v-model:value="formData.refuseReason" show-count placeholder="请输入" :rows="2" :maxlength="50" />
            </a-form-item>
            <a-form-item label="" :colon="false" class="button-container">
                <a-button type="primary" ghost @click="close">取消</a-button>
                <a-button class="action-button" type="primary" :loading="confirmLoading" @click="submit">确定</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script lang="ts">
import { reactive, ref, toRefs } from 'vue'
import { Form } from 'ant-design-vue'
import { refuseCancel } from '@/http/api/channel/channel-order'

export default {
    props: {
        remove: Function,
        onOk: Function,
        id: {
            type: Number,
            required: true
        }
    },
    setup(props: any) {
        const formRef = ref<typeof Form>()
        const state = reactive({
            visible: true,
            confirmLoading: false
        })

        const close = () => {
            state.visible = false
        }

        const formData = reactive({
            refuseReason: ''
        })
        const formRules = {
            refuseReason: [{ required: true, message: '请输入拒绝取消原因' }]
        }

        const submit = async () => {
            try {
                if (state.confirmLoading) {
                    return
                }
                state.confirmLoading = true
                await formRef.value?.validate()
                await refuseCancel(props.id, formData.refuseReason)
                close()
                props.onOk()
            } catch (err) {
                state.confirmLoading = false
                throw err
            }
        }

        return {
            formRef,
            close,
            formData,
            formRules,
            submit,
            ...toRefs(state)
        }
    }
}
</script>

<style scoped lang="scss">
.form .ant-input {
    height: auto;
    resize: none;
    line-height: inherit;
}

.button-container {
    margin-bottom: 0;
    padding-top: 20px;
    text-align: right;

    .action-button {
        margin-left: 16px;
    }
}
</style>
