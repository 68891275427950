<template>
    <div class="wrapper" @click="openModal">
        <div class="header">
            <span v-if="PayTypeCodeMap[data.payTypeCode]" class="tag">{{ PayTypeCodeMap[data.payTypeCode] }}</span>
            <span>{{ otherTitle }}</span>
            <span v-if="data.status === OtaOrderStatus.wait" class="status">&nbsp;(待确认)</span>
            <span v-if="data.status === OtaOrderStatus.confirmed" class="status confirmed">&nbsp;(已接单)</span>
        </div>
        <div class="body">
            <div class="row">
                <p class="name">{{ data.contactName }}</p>
                <span>{{ data.checkinDate }}-{{ data.checkoutDate }}&nbsp;&nbsp;{{ data.interval }}{{ data.hourFlag ? '小时' : '晚' }}</span>
                <img v-if="getIcon(data.tagType)" class="icon" :src="getIcon(data.tagType)" />
            </div>
            <div class="row">
                <span>{{ data.productName }}&nbsp;{{ data.roomNum }}间</span>
            </div>
            <div class="row common-flex">
                <div class="tag">{{ data.priceType === '1' ? '底' : '卖' }}价&nbsp;¥{{ data.orderPrice }}</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { notification } from 'ant-design-vue'
import { useModal } from '@/hooks/useModal'
import OtaOrderModal from '@/business-components/ota-message/OtaOrderModal.vue'
import { OtaOrderStatus } from '@/web-socket/ota/interfaces'
import { PayTypeCodeMap } from '@/business-components/constants'
import yuanqi from '@/assets/icons/yuanqi.png'
import jinriyudi from '@/assets/icons/jinriyudi.png'
import mingriyudi from '@/assets/icons/mingriyudi.png'

interface Props {
    title: string
    notifyKey: string
    data: any
}

export default {
    props: {
        notifyKey: {
            type: String,
            required: true
        },
        title: String,
        data: {
            type: Object,
            required: true
        }
    },
    setup(props: Props) {
        const map = {
            [OtaOrderStatus.wait]: `您有一个新的订单`,
            [OtaOrderStatus.confirmed]: `您有一个新的订单`,
            [OtaOrderStatus.rejected]: `您有一个订单已拒单`,
            [OtaOrderStatus.cancelled]: `您有一个订单已取消`
        }

        const openModal = () => {
            notification.close(props.notifyKey)
            useModal(OtaOrderModal, {
                data: props.data,
                otherTitle: undefined
            })
        }

        const IconMap = {
            1: jinriyudi,
            2: mingriyudi,
            3: yuanqi
        }
        const getIcon = (val: string) => IconMap[val]

        return {
            OtaOrderStatus,
            openModal,
            otherTitle: map[props.data.status] || '',
            PayTypeCodeMap,
            getIcon
        }
    }
}
</script>

<style lang="scss">
.ant-notification-notice.ota-message-notification {
    width: 400px;
    padding: 10px;

    .ant-notification-notice-message {
        display: none;
    }

    .ant-notification-notice-description {
        height: auto;
    }

    .ant-notification-close-icon {
        width: 12px;
        height: 12px;
    }
}
</style>

<style lang="scss" scoped>
.wrapper {
    color: #064d70;

    p {
        margin-bottom: 0;
    }

    .header {
        font-size: 16px;
        font-weight: 600;

        span {
            vertical-align: middle;
        }

        .tag {
            padding: 0 5px;
            line-height: 1;
            color: #f16d4a;
            font-size: 12px;
            margin-right: 4px;
            border-radius: 2px;
            border: 1px solid #f16d4a;
        }

        .status {
            color: #f16d4a;

            &.confirmed {
                color: #064d70;
            }
        }
    }

    .body {
        padding-top: 10px;

        .row {
            padding-bottom: 4px;

            .name {
                width: 94px;
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .name,
            span {
                vertical-align: middle;
            }

            .tag {
                margin-top: 4px;
                color: #f16d4a;
                padding: 0 6px;
                margin-right: 8px;
                border-radius: 2px;
                background: rgba(255, 136, 0, 0.06);
            }

            .icon {
                height: 18px;
                margin-left: 6px;
            }
        }
    }
}
</style>
