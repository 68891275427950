import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import { MessageEnum, OrderMessageTypeEnum, SingleHotelSystemTypeEnum } from '@/web-socket/interfaces'
import { isOtaOrderType, openOtaBillPayNotify, openOtaBillRejectInvoiceNotify, openOtaBillRejectNotify } from '@/web-socket/ota'
import { notifyMessage } from '@/web-socket/notify'
import { createVNode } from 'vue'

let stompClient
let timer = 0

export const initSocket = (hotelCode, accountId, count = 1) => {
    connectionSocket(hotelCode, accountId, count)
}

export function disconnectSocket() {
    if (stompClient) {
        try {
            window.clearTimeout(timer)
            stompClient.disconnect(() => {
                console.log('disconnect callback')
            })
            stompClient = null
        } catch (error) {
            console.error(error)
        }
    }
}

function connectionSocket(hotelCode, accountId, count) {
    const num = count || 1
    if (stompClient == null || !stompClient.connected) {
        const socket = new SockJS(process.env.VUE_APP_SOCKET_API_HOST, null, {
            timeout: 15000,
            transports: ['websocket']
        })
        console.log('socket url', process.env.VUE_APP_SOCKET_API_HOST)
        stompClient = Stomp.over(socket)

        stompClient.connect(
            {
                uniqueId: `${hotelCode}-${accountId}`,
                version: '1.1'
            },
            () => {
                stompClient.subscribe('/' + hotelCode, (response) => {
                    console.log('response', response.body)
                    if (response.body) {
                        const messageObj = JSON.parse(response.body)
                        console.log('messageObj', messageObj)
                        // 消息回调
                        const params = JSON.stringify({
                            msgId: messageObj.msgId,
                            recordId: messageObj.recordId
                        })
                        stompClient.send('/msg/ack', {}, params)

                        if (messageObj.businessType === MessageEnum.SingleHotelSystem) {
                            switch (messageObj.businessCustomType) {
                                case SingleHotelSystemTypeEnum.OTA_BILL_REJECT:
                                    // OTA 酒店账单驳回消息
                                    openOtaBillRejectNotify(JSON.parse(messageObj.content))
                                    break
                                case SingleHotelSystemTypeEnum.OTA_BILL_PAY:
                                    // OTA 酒店账单打款消息
                                    openOtaBillPayNotify(JSON.parse(messageObj.content))
                                    break
                                case SingleHotelSystemTypeEnum.OTA_WAITING_BILL_REJECT_MSG:
                                    // OTA 酒店开票驳回消息
                                    openOtaBillRejectInvoiceNotify(messageObj.content)
                                    break
                                default:
                                    break
                            }
                        }

                        if (messageObj.businessType === MessageEnum.Order) {
                            switch (messageObj.businessCustomType) {
                                case OrderMessageTypeEnum.INVOICE_SUCCESS:
                                    // 开票成功
                                    notifyMessage(messageObj.msgTitle, messageObj.msgTitle, createVNode('span', {}, messageObj.msgSubTitle))
                                    break
                                case OrderMessageTypeEnum.OTA_ORDER_MESSAGE:
                                    // OTA 订单通知
                                    isOtaOrderType(JSON.parse(messageObj.content))
                                    break
                                default:
                                    break
                            }
                        }
                    }
                })
            },
            (err) => {
                // 连接发生错误时的处理函数
                console.log('socket连接失败')
                console.error(err)
                if (num < 10) {
                    timer = window.setTimeout(() => {
                        initSocket(hotelCode, accountId, num + 1)
                    }, 10000 * num)
                } else {
                    console.log('放弃重连了')
                }
            }
        )
    }
}
