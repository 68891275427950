// 接口返回值data不能为这个，否则会判为请求失败
export const errorResult = '__ERROR_RESULT__'
export const CONFIG_CENTER = '/configcenter'
export const CORE_CENTER = '/corecenter'
export const ADMIN_CENTER = '/admincenter'
export const CHARGE_SERVICE_CENTER = '/chargeservicecenter'
export const VALUE_ADDED_CENTER = '/valueaddedcenter'
export const OTA_CENTER = '/otacenter'
export const OPERATION_CENTER = '/operationcenter'
export const PAYMENT = '/payment'
