<template>
    <a-popover overlay-class-name="order-price-popover" placement="bottom" trigger="click" :get-popup-container="getPopupContainer" @visible-change="onVisibleChange">
        <template #content>
            <a-spin :spinning="loading">
                <div class="order-price-container common-flex">
                    <div class="item" v-for="item in data" :key="item.orderDate">
                        <div class="header">{{ item.orderDate }}</div>
                        <div class="body common-flex">
                            <p>¥{{ item.orderPrice }}</p>
                            <p>
                                <icon-font type="icon-zaocan" size="12" />
                                <span>{{ item.breakfastNum }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </a-spin>
        </template>
        <a-button type="link" size="small">明细</a-button>
    </a-popover>
</template>

<script lang="ts">
import { ref } from 'vue'
import moment from 'moment'
import IconFont from '@/components/iconfont'
import { isArray } from '@/utils/is'
import { getDayPriceList } from '@/http/api/channel/channel-order'

interface Props {
    id: number
    getPopupContainer: () => HTMLElement
}

export default {
    props: {
        id: {
            type: Number,
            required: true
        },
        getPopupContainer: {
            type: Function,
            required: true
        }
    },
    setup(props: Props) {
        const loading = ref(true)
        const data = ref<any[]>([])

        const onVisibleChange = (visible: boolean) => {
            if (visible && data.value.length === 0) {
                loading.value = true
                setTimeout(() => {
                    getDayPriceList(props.id)
                        .then((res) => {
                            if (isArray(res)) {
                                res.forEach((c) => {
                                    c.orderDate = moment(c.orderDate).format('MM-DD')
                                })
                                data.value = res
                            }
                        })
                        .finally(() => {
                            loading.value = false
                        })
                }, 300)
            }
        }

        return {
            loading,
            data,
            onVisibleChange
        }
    },
    components: {
        IconFont
    }
}
</script>

<style lang="scss" scoped>
.order-price-container {
    max-width: 420px;
    min-width: 60px;
    min-height: 78px;
    flex-wrap: wrap;
    max-height: 316px;
    overflow: auto;

    p {
        margin-bottom: 0;
    }

    .item {
        width: 60px;
        border-bottom: 1px solid #d3d3d3;

        &:not(:last-child) {
            border-right: 1px solid #d3d3d3;
        }

        .header {
            color: #fff;
            line-height: 30px;
            text-align: center;
            background-color: $primary-color;
        }

        .body {
            height: 48px;
            line-height: 18px;
            flex-direction: column;
            justify-content: center;

            span {
                vertical-align: middle;
            }
        }
    }
}
</style>

<style lang="scss">
.order-price-popover .ant-popover-inner-content {
    padding: 0;
}
</style>
