<template>
    <a-config-provider :locale="locale">
        <router-view v-slot="{ Component }">
            <component :is="Component" />
        </router-view>
    </a-config-provider>
</template>

<script setup lang="ts">
import { watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import locale from 'ant-design-vue/es/locale/zh_CN'
import { initSocket, disconnectSocket } from '@/web-socket'
import { speckText } from '@/utils/tts'

const store = useStore()

onMounted(() => {
    window.setTimeout(() => {
        const search = window.location.search || ''
        if (search && search.indexOf('tts-test') !== -1) {
            speckText('自动来单，满房超简单')
        }
        watch(
            () => store.getters.isLogin,
            (val) => {
                if (val) {
                    const { hotelCode, accountId } = store.getters.base
                    initSocket(hotelCode, accountId)
                } else {
                    disconnectSocket()
                }
            },
            {
                immediate: true
            }
        )
    }, 2000)
})
</script>

<style lang="scss">
#app {
    color: #333;
    font-size: 14px;
}
</style>
