import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-effc75d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = {
  key: 0,
  class: "tag"
}
const _hoisted_3 = {
  key: 1,
  class: "status"
}
const _hoisted_4 = {
  key: 2,
  class: "status confirmed"
}
const _hoisted_5 = { class: "body" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "name" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "row common-flex" }
const _hoisted_11 = { class: "tag" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "wrapper",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.openModal && $setup.openModal(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($setup.PayTypeCodeMap[$props.data.payTypeCode])
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($setup.PayTypeCodeMap[$props.data.payTypeCode]), 1))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, _toDisplayString($setup.otherTitle), 1),
      ($props.data.status === $setup.OtaOrderStatus.wait)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, " (待确认)"))
        : _createCommentVNode("", true),
      ($props.data.status === $setup.OtaOrderStatus.confirmed)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, " (已接单)"))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString($props.data.contactName), 1),
        _createElementVNode("span", null, _toDisplayString($props.data.checkinDate) + "-" + _toDisplayString($props.data.checkoutDate) + "  " + _toDisplayString($props.data.interval) + _toDisplayString($props.data.hourFlag ? '小时' : '晚'), 1),
        ($setup.getIcon($props.data.tagType))
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "icon",
              src: $setup.getIcon($props.data.tagType)
            }, null, 8, _hoisted_8))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("span", null, _toDisplayString($props.data.productName) + " " + _toDisplayString($props.data.roomNum) + "间", 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString($props.data.priceType === '1' ? '底' : '卖') + "价 ¥" + _toDisplayString($props.data.orderPrice), 1)
      ])
    ])
  ]))
}