<template>
    <a-modal v-model:visible="visible" :width="600" :body-style="{ padding: '24px 38px 24px 38px' }" :title="title" centered :mask-closable="false" :after-close="remove" :footer="null">
        <a-spin class="spin" :spinning="loading" :delay="500" tip="加载中...">
            <div class="header">
                <span v-if="data.invoiceFlag === false" class="tag">无需开票</span>
                <span v-if="PayTypeCodeMap[data.payTypeCode]" class="tag">{{ PayTypeCodeMap[data.payTypeCode] }}</span>
                <span class="text">{{ data.productName }}&nbsp;{{ data.roomNum }}间</span>
            </div>
            <div class="body">
                <div class="price-container common-flex">
                    <div class="tag">{{ data.priceType === '1' ? '底' : '卖' }}价&nbsp;¥{{ data.orderPrice }}</div>
                    <span class="contain">{{ data.breakfast ? '含早' : '不含早' }}</span>
                    <price-popover :id="data.id" :get-popup-container="getPopupContainer" />
                </div>
                <div class="info-container">
                    <div class="row common-flex">
                        <span class="label">客人姓名</span>
                        <span class="value">{{ data.contactName }}</span>
                    </div>
                    <div class="row common-flex">
                        <span class="label">住宿日期</span>
                        <span class="value">{{ data.checkinDate }}-{{ data.checkoutDate }}&nbsp;&nbsp;{{ data.interval }}{{ data.hourFlag ? '小时' : '晚' }}</span>
                        <img v-if="getIcon(data.tagType)" class="icon" :src="getIcon(data.tagType)" />
                    </div>
                    <div class="row common-flex">
                        <span class="label">提示</span>
                        <span class="value">{{ data.memo }}</span>
                    </div>
                </div>
            </div>
            <div class="footer">
                <template v-if="data.status === OtaOrderStatus.wait">
                    <a-button type="primary" ghost @click="rejection">拒单</a-button>
                    <a-button type="primary" :loading="confirmLoading" @click="confirmOrderHandle">接单</a-button>
                </template>
                <a-button v-else type="primary" ghost @click="close">关闭</a-button>
            </div>
        </a-spin>
    </a-modal>
</template>

<script lang="ts">
import { reactive, toRefs, createVNode } from 'vue'
import { message, Modal } from 'ant-design-vue'
import { OtaOrderStatus } from '@/web-socket/ota/interfaces'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { PayTypeCodeMap } from '@/business-components/constants'
import PricePopover from '@/business-components/price-popover/Index.vue'
import { useModal } from '@/hooks/useModal'
import yuanqi from '@/assets/icons/yuanqi.png'
import jinriyudi from '@/assets/icons/jinriyudi.png'
import mingriyudi from '@/assets/icons/mingriyudi.png'
import { confirmOrder, rejectOrder } from '@/http/api/channel/channel-order'
import PreCheckMessageModal from '@/business-components/platoon-room-modal/PreCheckMessageModal.vue'

interface Props {
    data: any
    remove: () => void
    onOk: () => void
}

export default {
    components: {
        PricePopover
    },
    props: {
        remove: Function,
        onOk: Function,
        data: {
            type: Object,
            required: true
        }
    },
    setup(props: Props) {
        const map = {
            [OtaOrderStatus.wait]: '您有一个新的订单 (待确认)',
            [OtaOrderStatus.confirmed]: '您有一个新的订单 (已接单)',
            [OtaOrderStatus.rejected]: '您有一个订单已拒单',
            [OtaOrderStatus.cancelled]: '您有一个订单已取消'
        }

        const state = reactive({
            visible: true,
            loading: false,
            confirmLoading: false
        })

        const close = () => {
            state.visible = false
        }

        const rejection = () => {
            Modal.confirm({
                title: '拒单会影响酒店在OTA上的评分，是否确认拒单？',
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                cancelText: '取 消',
                okText: '确 认',
                zIndex: 1001,
                cancelButtonProps: {
                    type: 'primary',
                    ghost: true
                },
                onOk() {
                    return rejectOrder(props.data.id).then(() => {
                        close()
                    })
                }
            })
        }

        const confirmOrderHandle = async () => {
            try {
                state.confirmLoading = true
                const res = await confirmOrder(props.data.id, false, [])
                if (res.code === 0) {
                    props.onOk?.()
                    useModal(
                        PreCheckMessageModal,
                        {
                            msg: '请确保能正常安排客人入住，若无法原单安排，将会产生赔付。',
                            okBtnText: '我已知晓'
                        },
                        () => {
                            state.visible = false
                        }
                    )
                } else {
                    message.error(res.message)
                }
            } finally {
                state.confirmLoading = false
            }
        }

        const IconMap = {
            1: jinriyudi,
            2: mingriyudi,
            3: yuanqi
        }
        const getIcon = (val: string) => IconMap[val]

        return {
            OtaOrderStatus,
            close,
            rejection,
            title: map[props.data.status] || '',
            PayTypeCodeMap,
            getPopupContainer: (el) => el.parentElement || document.body,
            getIcon,
            confirmOrderHandle,
            ...toRefs(state)
        }
    }
}
</script>

<style lang="scss" scoped>
.ant-spin-nested-loading {
    color: #333;

    p {
        margin-bottom: 0;
    }

    .header {
        font-size: 16px;

        span {
            vertical-align: middle;
        }

        .tag {
            padding: 0 7px;
            line-height: 1;
            color: #f16d4a;
            margin-right: 6px;
            border-radius: 2px;
            border: 1px solid #f16d4a;
        }

        .text {
            font-weight: 500;
        }
    }

    .body {
        .price-container {
            font-size: 16px;
            margin-top: 14px;

            .tag {
                color: #f16d4a;
                padding: 0 6px;
                margin-right: 10px;
                border-radius: 2px;
                background: rgba(255, 136, 0, 0.06);
            }

            .contain {
                color: #f16d4a;
                font-size: 14px;
            }

            :deep(.ant-popover) {
                top: 60px !important;
            }

            :deep(.ant-popover-inner-content) {
                padding: 0;
            }

            :deep(.ant-btn) {
                min-width: 0 !important;
            }
        }

        .info-container {
            padding-top: 50px;

            .row {
                margin-bottom: 6px;
                align-items: flex-start;

                .label {
                    flex: 0 0 56px;
                    color: #666;
                    text-align: right;
                    margin-right: 12px;
                }

                .value {
                    word-break: break-all;
                }

                .icon {
                    height: 18px;
                    margin: 3px 0 0 6px;
                }
            }
        }
    }

    .footer {
        padding-top: 26px;
        text-align: right;

        .ant-btn {
            width: 120px;
            height: 34px;
            margin-left: 20px;
        }
    }
}
</style>
