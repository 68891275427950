import { createVNode } from 'vue'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import { toFixed } from '@/utils/amount'
import { OtaOrderStatus } from '@/web-socket/ota/interfaces'
import OtaNotify from '@/business-components/ota-message/OtaNotify.vue'
import { notifyMessage } from '@/web-socket/notify'
import { useModal } from '@/hooks/useModal'
import ApplyCancellationModal from '@/business-components/ota-message/ApplyCancellationModal.vue'
import { getGradOrderModalVisible, setGradOrderModalVisible } from '@/business-components/ota-message/grad-modal-visible'
import { speckText } from '@/utils/tts'
import GradOrderModal from '@/business-components/ota-message/GradOrderModal.vue'

const otaNotifyMessage = (data: any) => {
    const key = uuid()
    const defaultText = '您有一个新订单，请尽快确认'
    const playTextMap = {
        [OtaOrderStatus.wait]: defaultText,
        [OtaOrderStatus.confirmed]: '您有一个新订单接单啦',
        [OtaOrderStatus.rejected]: '您有一个订单超时拒单了',
        [OtaOrderStatus.cancelled]: '您有一个订单被取消'
    }
    const playText = playTextMap[data.status] || defaultText
    data.checkinDate = moment(data.checkinDate).format('YYYY.MM.DD')
    data.checkoutDate = moment(data.checkoutDate).format('YYYY.MM.DD')
    if (typeof data.orderPrice === 'number') data.orderPrice = data.orderPrice / 100
    notifyMessage(playText, '', createVNode(OtaNotify, { notifyKey: key, data, title: playText }), { key, class: 'socket-message-notification ota-message-notification', duration: null })
}

export const openOtaBillRejectNotify = (data: { billName: string; reason: string; amount: number }) => {
    const desc = createVNode('span', {}, [`"${data.billName}"的提现申请被驳回，账单金额：${toFixed(data.amount, { defaultValue: '', isDivide: true })}元，驳回原因：${data.reason}；`])
    notifyMessage('提现驳回提醒', '提现驳回提醒', desc, { duration: null })
}

export const openOtaBillRejectInvoiceNotify = (data: string) => {
    const desc = createVNode('span', {}, [data])
    notifyMessage('开票驳回提醒', '开票驳回提醒', desc, { duration: null })
}

export const openOtaBillPayNotify = (data: { amount: number }) => {
    const desc = createVNode('span', {}, [`您有一笔分销订单款项已打款，账单金额：${toFixed(data.amount, { defaultValue: '', isDivide: true })}元，请注意查收`])
    notifyMessage('提现打款提醒', '提现打款提醒', desc, { duration: null })
}

export const isOtaOrderType = (data: any) => {
    if (data.status === 9) {
        if (typeof data.orderPrice === 'number') data.orderPrice = data.orderPrice / 100
        useModal(ApplyCancellationModal, { data })
        return
    }
    if (data.modelType === 3 && data.status === 0) {
        if (!getGradOrderModalVisible()) {
            setGradOrderModalVisible(true)
            speckText('您有一个新订单，请注意查收')
            if (typeof data.orderPrice === 'number') data.orderPrice = data.orderPrice / 100
            useModal(GradOrderModal, { data })
        }
    } else {
        otaNotifyMessage(data)
    }
}

// setTimeout(() => {
//     // openOtaBillRejectNotify({ billName:'阿萨德的岁数大', reason: '阿大放送萨芬水电费功夫', amount: 123 })
//     // openOtaBillPayNotify({ amount: 2333 })
//     // openOtaBillRejectInvoiceNotify('阿斯顿发个的大飞哥')
//     isOtaOrderType({
//         id: 1,
//         status: 0,
//         modelType: 3,
//         title: 'title',
//         contactName: 'contactName',
//         payTypeCode: 0,
//         interval: 3,
//         roomNum: 2,
//         checkinDate: '2013-12-23',
//         checkoutDate: '2016-12-23',
//         orderPrice: 23444,
//         productName: 'productName',
//         nowTime: 1715680853842,
//         rejectTime: 1715671853842,
//         memo: 'memo阿萨德梵蒂冈查水电费供货商'
//     })
// }, 1000)
