import type { App } from 'vue'
import 'ant-design-vue/dist/antd.less'
import DatePicker from 'ant-design-vue/es/date-picker/moment'

import {
    Modal,
    Table,
    Menu,
    Input,
    Button,
    Form,
    Card,
    Checkbox,
    Radio,
    Layout,
    Descriptions,
    Badge,
    Switch,
    Alert,
    Collapse,
    Tooltip,
    Dropdown,
    InputNumber,
    Select,
    Tag,
    Carousel,
    Row,
    Col,
    Popover,
    Popconfirm,
    Spin,
    ConfigProvider,
    LocaleProvider,
    Empty,
    Tree,
    Pagination,
    Statistic
} from 'ant-design-vue'

export function setupAntd(app: App<Element>) {
    app.component('AButton', Button)

    app.use(Form)
        .use(Input)
        .use(Modal)
        .use(Table)
        .use(Menu)
        .use(Card)
        .use(Checkbox)
        .use(Layout)
        .use(Descriptions)
        .use(Badge)
        .use(Switch)
        .use(Alert)
        .use(Collapse)
        .use(Radio)
        .use(Dropdown)
        .use(DatePicker)
        .use(Tooltip)
        .use(InputNumber)
        .use(Select)
        .use(Tag)
        .use(Carousel)
        .use(Row)
        .use(Col)
        .use(Popover)
        .use(Popconfirm)
        .use(Spin)
        .use(ConfigProvider)
        .use(LocaleProvider)
        .use(Empty)
        .use(Tree)
        .use(Pagination)
        .use(Statistic)
}
