import { App } from 'vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { createStorage } from '@/utils/cache'
import store from '@/store'

const storage = createStorage()

export const routes: RouteRecordRaw[] = [
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/404/Index.vue')
    },
    {
        path: '/',
        name: '/',
        component: () => import(/* webpackChunkName: "index" */ '@/views/index/Index.vue'),
        meta: {
            auth: true
        }
    },
    {
        path: '/login',
        name: '/login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/login/Index.vue')
    }
    // {
    //     path: '/',
    //     name: '/index',
    //     component: () => import('@qiangdanbao/views/index/Index.vue'),
    //     meta: {
    //         title: '首页'
    //     }
    // }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export const setupRouter = (app: App) => {
    app.use(router)
    router.beforeEach((to, from, next) => {
        const isLogin = store.getters.isLogin
        if (to.name === '/login' && isLogin) {
            next('/')
            return
        }
        if (to.meta.auth && !isLogin) {
            next('/login')
            return
        }
        next()
    })
    return new Promise<void>((resolve) => {
        router.isReady().finally(() => resolve())
    })
}

export default router
