export interface EncryptionParams {
    key: string
    iv: string
}

export class Encryption {
    constructor(opt: EncryptionParams) {
        const { key, iv } = opt
    }

    get getOpt() {
        return {}
    }

    // 加密
    encryptByAES(str: string) {}

    // 解密
    decryptByAES(str: string) {}
}
export default Encryption
