import http from '@/http'
import { ADMIN_CENTER, OTA_CENTER, OPERATION_CENTER } from '@/http/constants'
import { RequestEnum } from '@/enums/httpEnum'

enum Api {
    getOtaOrderList = '/desktop/getOtaOrderList',
    getRejectReason = '/getRejectReason',
    getOtaSourceDict = '/getOtaSourceDict',
    getDayPriceList = '/getDayPriceList',
    rejected = '/rejected',
    checkConfirmOrder = '/checkConfirmOrder',
    confirmOrder = '/confirmOrder',
    acceptCancel = '/acceptCancel',
    refuseCancel = '/refuseCancel',
    noTokenReservationRoomDisplay = '/no-token/reservationRoomDisplay',
    orderAddCheckOta = '/orderAddCheckOta',
    confirmed = '/confirmed',
    perfectRoomorder = '/perfectRoomorder'
}

// 订单列表
export function getOtaOrderList(params: any, data: any) {
    return http.request<any>({
        url: OTA_CENTER + Api.getOtaOrderList,
        method: RequestEnum.POST,
        params: { pageRq: params, ...data }
    })
}

// 获取拒单备注
export function getRejectReason() {
    return http.request({
        url: ADMIN_CENTER + Api.getRejectReason,
        method: RequestEnum.GET
    })
}

// 渠道订单来源字典
export function getOtaSourceDict() {
    return http.request({
        url: `${OTA_CENTER}${Api.getOtaSourceDict}`,
        method: RequestEnum.GET
    })
}

// ota订单明细列表
export function getDayPriceList(orderId: number) {
    return http.request<any[]>({
        url: `${ADMIN_CENTER}${Api.getDayPriceList}?orderId=${orderId}`,
        method: RequestEnum.GET
    })
}

// 拒单
export function rejectOrder(orderId: number, code?: string | null) {
    const isCode = code ? `&code=${code}` : ''
    return http.request<any[]>(
        {
            url: `${ADMIN_CENTER}${Api.rejected}?orderId=${orderId}${isCode}`,
            method: RequestEnum.GET
        },
        {
            successMessageText: '操作成功',
            errorMessageText: '操作失败'
        }
    )
}

// 校验是否待入住
export function checkConfirmOrder(orderNumber: number, channelId: string) {
    return http.request<{ code: number; result: number; message: string }>(
        {
            url: `${ADMIN_CENTER}${Api.checkConfirmOrder}`,
            method: RequestEnum.GET,
            params: { orderNumber, channelId }
        },
        {
            isTransformRequestResult: true
        }
    )
}

// 确认入住和确认取消
export function confirmCancleOrCheckIn(otaOrderId: number, code: number) {
    return http.request<{ code: number; message: string; result: string }>(
        {
            url: `${ADMIN_CENTER}${Api.confirmOrder}/${otaOrderId}/${code}`,
            method: RequestEnum.PUT
        },
        {
            successMessageText: '操作成功',
            errorMessageText: '操作失败'
        }
    )
}

export function acceptCancel(orderId: number) {
    return http.request(
        {
            url: OTA_CENTER + Api.acceptCancel,
            method: RequestEnum.POST,
            params: { orderId }
        },
        {
            successMessageText: '操作成功',
            errorMessageText: '操作失败'
        }
    )
}

export function refuseCancel(orderId: number, refuseReason: string) {
    return http.request(
        {
            url: OTA_CENTER + Api.refuseCancel,
            method: RequestEnum.POST,
            params: { orderId, refuseReason }
        },
        {
            successMessageText: '操作成功',
            errorMessageText: '操作失败'
        }
    )
}

// OTA接单选房
export function noTokenReservationRoomDisplay(otaOrderId: number) {
    return http.request({
        url: `${OTA_CENTER}${Api.noTokenReservationRoomDisplay}`,
        method: RequestEnum.GET,
        params: { otaOrderId }
    })
}

// 接单前预检
export function orderAddCheckOta(
    orderId: number,
    rooms: [{ roomId: number; roomTypeId: number; roomTypeName: string }]
) {
    return http.request<{ code: number; message: string; result: any }>(
        {
            url: `${OTA_CENTER}${Api.orderAddCheckOta}`,
            method: RequestEnum.POST,
            params: { orderId, rooms }
        },
        {
            isTransformRequestResult: true,
            isShowMessage: false
        }
    )
}

// 接单
export function confirmOrder(orderId: number, checkFlag: boolean, rooms?: { roomId: number; roomTypeId: number }[]) {
    return http.request<{
        code: number
        message: string
        result: { arrangeRoomData: Record<string, number>; arriveTime: string; leaveTime: string; orderId: number }
    }>(
        {
            url: `${ADMIN_CENTER}${Api.confirmed}`,
            method: RequestEnum.POST,
            params: {
                orderId,
                checkFlag,
                rooms
            }
        },
        {
            isTransformRequestResult: true,
            isShowMessage: false
        }
    )
}

// 批量排房
export function perfectRoomOrder(
    params: { child: number[]; orderId?: number; teamBatchId?: number },
    successMessageText = '操作成功'
) {
    return http.request(
        {
            url: `${OPERATION_CENTER}${Api.perfectRoomorder}`,
            method: RequestEnum.POST,
            params
        },
        {
            isShowErrorMessage: true,
            successMessageText,
            errorMessageText: '操作失败'
        }
    )
}
