const Decimal = require('decimal.js')
import { isNumber, isString } from './is'

export interface Options {
    decimalPlaces?: number // 保留小数位
    isDivide?: boolean // 是否除以 100
    isAbs?: boolean // 是否取绝对值
    defaultValue?: string // 解析失败时的默认值
}

const defaultOptions = {
    decimalPlaces: 2,
    isDivide: false,
    isAbs: false
}

/*
 * 金额保留小数，不四舍五入
 * toFixed(100) ===> 1.00
 * toFixed(19989) ===> 199.89
 * toFixed('不是一个合法的数字') ===> '不是一个合法的数字'
 * */
export const toFixed = (value: unknown, options?: Options) => {
    options = Object.assign(Object.create(null), defaultOptions, options)
    if (isNumber(value) || isString(value)) {
        const newValue = Number(value)
        if (Number.isNaN(newValue)) return options!.defaultValue ?? value.toString()
        let decimal = new Decimal(newValue)
        if (options!.isDivide) decimal = decimal.div(100)
        if (options!.isAbs) decimal = decimal.abs()
        return decimal.toFixed(options!.decimalPlaces ?? 2, Decimal.ROUND_DOWN)
    }
    return options!.defaultValue ?? value + ''
}

export const MaxAmount = 999999.99

export enum ErrorEnum {
    invalid = 1,
    max = 2,
    min = 3,
    places = 4,
    minOrEqual = 5
}

export interface ValidateOptions {
    min?: number
    minOrEqual?: boolean
    max?: number
    places?: number
    errorMap?: Record<string, (options: ValidateOptions) => string>
}

const defaultValidateOptions = {
    min: 0,
    minOrEqual: false,
    max: MaxAmount,
    places: 2
}

const getError = (value: Decimal, errorKeys: number[], options: ValidateOptions): [string[] | null, Decimal] => {
    const errors: string[] = []
    const errorMap = options.errorMap || {}
    errorKeys.forEach((key) => {
        let text = ''
        switch (key) {
            case ErrorEnum.invalid:
                text = '请输入正确的金额'
                if (errorMap[ErrorEnum.invalid]) {
                    text = errorMap[ErrorEnum.invalid](options)
                }
                errors.push(text)
                break
            case ErrorEnum.max:
                // text = `金额必须小于${ options.max }`;
                text = '请输入正确的金额'
                if (errorMap[ErrorEnum.max]) {
                    text = errorMap[ErrorEnum.max](options)
                }
                errors.push(text)
                break
            case ErrorEnum.min:
                // text = `金额必须大于${ options.min }`;
                text = '请输入正确的金额'
                if (errorMap[ErrorEnum.min]) {
                    text = errorMap[ErrorEnum.min](options)
                }
                errors.push(text)
                break
            case ErrorEnum.minOrEqual:
                // text = `金额必须大于等于${ options.min }`;
                text = '请输入正确的金额'
                if (errorMap[ErrorEnum.minOrEqual]) {
                    text = errorMap[ErrorEnum.minOrEqual](options)
                }
                errors.push(text)
                break
            case ErrorEnum.places:
                // text = '金额最多两位小数';
                text = '请输入正确的金额'
                if (errorMap[ErrorEnum.places]) {
                    text = errorMap[ErrorEnum.places](options)
                }
                errors.push(text)
                break
        }
    })
    const hasError = errors.length > 0
    if (hasError) {
        return [errors, new Decimal(0)]
    }
    return [null, value ?? new Decimal(0)]
}

// 金额校验
export const validateAmount = (value: string | number | Decimal, options?: ValidateOptions) => {
    let decimal: Decimal | null = null
    const errorKeys: number[] = []
    options = Object.assign(Object.create(null), defaultValidateOptions, options)
    try {
        decimal = new Decimal(value)

        // 例如：1.  /  2.  / 100.
        if (isString(value) && value.endsWith('.')) {
            errorKeys.push(ErrorEnum.invalid)
        }

        // 请输入正确的金额
        if (decimal.isNaN()) errorKeys.push(ErrorEnum.invalid)

        // 金额必须小于
        if (decimal.gt(options?.max ?? MaxAmount)) errorKeys.push(ErrorEnum.max)

        if (options?.minOrEqual) {
            // 金额必须大于等于
            if (!decimal.gte(options?.min ?? 0)) errorKeys.push(ErrorEnum.minOrEqual)
        } else {
            // 金额必须大于
            if (!decimal.gt(options?.min ?? 0)) errorKeys.push(ErrorEnum.min)
        }

        // 金额最多两位小数
        if (decimal.decimalPlaces() > (options?.places ?? 2)) errorKeys.push(ErrorEnum.places)
    } catch (err) {
        errorKeys.push(ErrorEnum.invalid)
    }
    return getError(decimal!, errorKeys, options!)
}
