import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6dcfc02e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "btn-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    class: "pre-check-message-modal",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event)),
    width: 440,
    closable: true,
    "mask-closable": false,
    centered: "",
    "after-close": $props.remove,
    footer: null,
    bodyStyle: { padding: '54px 30px 36px' },
    onCancel: $setup.onCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString($props.msg), 1),
      _createElementVNode("div", _hoisted_2, [
        ($props.showCancelBtn)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "btn ghost",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.close && $setup.close(...args)))
            }, "取消"))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "btn",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.okHandle && $setup.okHandle(...args)))
        }, _toDisplayString($props.okBtnText ?? '知道了'), 1)
      ])
    ]),
    _: 1
  }, 8, ["visible", "after-close", "onCancel"]))
}