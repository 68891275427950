import { createApp } from 'vue'
import moment from 'moment'
import 'moment/dist/locale/zh-cn'
import '@/scss/index.scss'
import App from './App.vue'
import { setupAntd } from '@/plugins/antd'
import { setupRouter } from '@/router'
import { setApp } from '@/hooks/useModal'
import { setupStore } from '@/store'

moment.locale('zh-cn')

const app = createApp(App)

;(async () => {
    setupStore(app)
    setupAntd(app)
    setApp(app)
    await setupRouter(app)
    app.mount('#app')
})()
