<template>
    <a-modal
        class="pre-check-message-modal"
        v-model:visible="visible"
        :width="440"
        :closable="true"
        :mask-closable="false"
        centered
        :after-close="remove"
        :footer="null"
        :bodyStyle="{ padding: '54px 30px 36px' }"
        @cancel="onCancel"
    >
        <div class="content">{{ msg }}</div>
        <div class="btn-box">
            <div class="btn ghost" v-if="showCancelBtn" @click="close">取消</div>
            <div class="btn" @click="okHandle">{{ okBtnText ?? '知道了' }}</div>
        </div>
    </a-modal>
</template>

<script lang="ts">
import { reactive, toRefs } from 'vue'

interface Props {
    remove: () => void
    onOk: () => void
    msg: string
    okBtnText: string
    showCancelBtn: boolean
    modalOptions: any
}

export default {
    props: {
        remove: Function,
        onOk: Function,
        msg: {
            type: String,
            default: ''
        },
        okBtnText: {
            type: String,
            default: undefined
        },
        showCancelBtn: {
            type: Boolean,
            default: false
        },
        modalOptions: Object
    },
    setup(props: Props) {
        const state = reactive({
            visible: true
        })

        const onCancel = () => {
            props.modalOptions?.onCancel?.()
        }

        const close = () => {
            state.visible = false
        }

        const okHandle = () => {
            close()
            props.onOk?.()
        }

        return {
            onCancel,
            okHandle,
            close,
            ...toRefs(state)
        }
    }
}
</script>

<style lang="scss" scoped>
.pre-check-message-modal {
    .content {
        line-height: 28px;
        font-size: 20px;
        color: #333333;
        text-align: center;
        margin-bottom: 32px;
        min-height: 84px;
    }

    .btn-box {
        text-align: center;

        .btn {
            display: inline-block;
            border: 1px solid $primary-color;
            width: 160px;
            height: 34px;
            line-height: 34px;
            background: $primary-color;
            border-radius: 4px;
            font-size: 14px;
            color: #ffffff;
            margin: 0 10px;
            cursor: pointer;

            &.ghost {
                color: #4ac3bb;
                background: #ffffff;
            }
        }
    }
}
</style>
