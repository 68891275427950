<template>
    <a-popover
        v-model:visible="visible"
        trigger="click"
        :placement="type === 'modal' ? 'topRight' : 'bottomRight'"
        :get-popup-container="
            type === 'modal'
                ? (triggerNode) => {
                      return triggerNode.parentNode || document.body
                  }
                : null
        "
    >
        <template #content>
            <p class="reject-title">拒绝原因</p>
            <a-radio-group v-if="outerDictWrapper" class="radio-group common-ant-radio-group-normal" v-model:value="rejectReason">
                <div v-for="item in outerDictWrapper" :key="item.code">
                    <a-radio class="radio" :value="item.code">{{ item.desc }} </a-radio>
                </div>
            </a-radio-group>
            <a-radio-group v-else class="radio-group common-ant-radio-group-normal" v-model:value="rejectReason">
                <div v-for="item in dictWrapper" :key="item.code">
                    <a-radio class="radio" :value="item.code">{{ item.desc }} </a-radio>
                </div>
            </a-radio-group>
            <a-button
                class="reject-submit"
                type="primary"
                :loading="confirmLoading"
                v-if="(dictWrapper && dictWrapper.length > 0) || (outerDictWrapper && outerDictWrapper.length > 0)"
                @click="submit"
            >
                确定
            </a-button>
        </template>
        <slot name="button"></slot>
    </a-popover>
</template>

<script lang="ts">
import { reactive, toRefs, computed, onMounted } from 'vue'
import { getRejectReason, rejectOrder } from '@/http/api/channel/channel-order'

interface Props {
    id: number
    type: string
    outerDict: any
    isTestOrder: boolean
}

export default {
    props: {
        id: {
            type: Number,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        outerDict: Array,
        isTestOrder: Boolean
    },
    emits: ['onOk'],
    setup(props: Props, { emit }) {
        const state = reactive({
            visible: false,
            confirmLoading: false,
            rejectReason: null as string | null,
            dict: [] as any
        })

        const outerDictWrapper = computed(() => {
            if (props.outerDict) {
                return props.outerDict.filter((c) => !(c.code === 3 && !props.isTestOrder))
            }
            return null
        })

        const dictWrapper = computed(() => state.dict.filter((c) => !(c.code === 3 && !props.isTestOrder)))

        const submit = async () => {
            if (state.rejectReason === null) return
            try {
                state.confirmLoading = true
                await rejectOrder(props.id, state.rejectReason)
                state.visible = false
                emit('onOk')
            } catch (err) {
                state.confirmLoading = false
                throw err
            }
        }

        onMounted(() => {
            if (!props.outerDict) {
                getRejectReason().then((res) => {
                    state.dict = res
                })
            }
        })

        return {
            outerDictWrapper,
            dictWrapper,
            ...toRefs(state),
            submit
        }
    }
}
</script>

<style lang="scss" scoped>
.ant-popover {

    .radio-group > div {
        margin-bottom: 6px;

        .ant-radio-wrapper {
            align-items: center;

            :deep(.ant-radio) {
                top: 0;
            }
        }
    }

    .reject-title {
        min-width: 305px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .reject-submit {
        margin: 5px calc(50% - 50px) !important;
    }

    :deep(.common-ant-radio-group-normal .ant-radio-wrapper span) {
        vertical-align: middle !important;
    }
}
</style>
