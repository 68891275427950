<template>
    <a-modal
        class="modal apply-cancellation-modal"
        v-model:visible="visible"
        title="您有一个“抢单宝”订单客人申请取消"
        :width="null"
        :bodyStyle="{ minWidth: '600px', padding: '30px 24px 12px 40px' }"
        centered
        :after-close="remove"
        :footer="null"
    >
        <div class="info-container">
            <div class="row first common-flex">
                <div class="row common-flex">
                    <span class="label">客人姓名</span>
                    <div class="value">{{ data.contactName }}</div>
                </div>
                <div class="price-container">
                    <span class="tag">无需开票</span>
                    <span v-if="PayTypeCodeMap[data.payTypeCode]" class="tag">{{ PayTypeCodeMap[data.payTypeCode] }}</span>
                    <span class="dijia">底价</span>
                    <span class="unit">¥</span>
                    <span class="price">{{ data.orderPrice }}</span>
                    <span class="contain">{{ data.breakfast ? '含早' : '不含早' }}</span>
                    <price-popover :id="data.id" :get-popup-container="getPopupContainer" />
                </div>
            </div>

            <div class="row common-flex">
                <span class="label">住宿日期</span>
                <span class="value">{{ data.checkinDate }}-{{ data.checkoutDate }}&nbsp;&nbsp;{{ data.interval }}{{ data.hourFlag ? '小时' : '晚' }}</span>
            </div>
            <div class="row common-flex">
                <span class="label">房型名称</span>
                <span class="value">{{ data.productName }}&nbsp;{{ data.roomNum }}</span>
            </div>
            <div class="row common-flex">
                <span class="label">取消原因</span>
                <span class="value">{{ data.memo }}</span>
            </div>
        </div>
        <div class="button-container">
            <a-button class="action-button" type="primary" ghost @click="reject">拒绝取消</a-button>
            <a-button class="action-button" type="primary" :loading="confirmLoading" @click="submit">接受取消</a-button>
        </div>
    </a-modal>
</template>

<script lang="ts">
import { createVNode, reactive, toRefs } from 'vue'
import { Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { useModal } from '@/hooks/useModal'
import RefuseCancelModal from './RefuseCancelModal.vue'
import PricePopover from '../price-popover/Index.vue'
import { PayTypeCodeMap } from '@/business-components/constants'
import { acceptCancel } from '@/http/api/channel/channel-order'

export default {
    props: {
        remove: Function,
        onOk: Function,
        data: {
            type: Object,
            required: true
        }
    },
    setup(props: any) {
        const state = reactive({
            visible: true,
            confirmLoading: false
        })

        const close = () => {
            state.visible = false
        }

        const reject = () => {
            useModal(RefuseCancelModal, { id: props.data.id }, close)
        }

        const submit = () => {
            Modal.confirm({
                title: '请确认客人实际入住情况，一旦接受取消，订单会立即退款',
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                cancelText: '取 消',
                okText: '确 认',
                cancelButtonProps: {
                    type: 'primary',
                    ghost: true
                },
                onOk() {
                    return acceptCancel(props.data.id).then(close)
                }
            })
        }

        return {
            PayTypeCodeMap,
            close,
            reject,
            submit,
            getPopupContainer: (el) => el.parentElement || document.body,
            ...toRefs(state)
        }
    },
    components: {
        PricePopover
    }
}
</script>

<style lang="scss">
.modal.apply-cancellation-modal .ant-modal-content {
    .ant-modal-header {
        background-color: #f16d4a;

        .ant-modal-title {
            color: #fff;
        }
    }

    .ant-modal-close .ant-modal-close-x .anticon-close {
        color: #fff;
    }
}
</style>

<style scoped lang="scss">
.modal {
    .info-container {
        .row {
            margin-bottom: 6px;
            align-items: flex-start;

            &.first {
                justify-content: space-between;

                .price-container {
                    line-height: 1;
                    font-size: 14px;

                    span {
                        vertical-align: middle;
                    }

                    .tag {
                        padding: 0 7px;
                        line-height: 1;
                        color: #f16d4a;
                        font-size: 12px;
                        margin-right: 6px;
                        border-radius: 2px;
                        border: 1px solid #f16d4a;
                    }

                    .dijia {
                        color: #666;
                    }

                    .unit {
                        padding: 0 2px 0 4px;
                    }

                    .unit,
                    .price {
                        color: #f16d4a;
                    }

                    .price {
                        font-size: 20px;
                        padding-right: 3px;
                    }

                    .contain {
                        color: #f16d4a;
                    }

                    :deep(.ant-popover-inner-content) {
                        padding: 0;
                    }

                    :deep(.ant-btn) {
                        padding: 0 0 0 8px;
                        min-width: 0 !important;
                    }
                }
            }

            .label {
                flex: 0 0 56px;
                color: #666;
                text-align: right;
                margin-right: 12px;
            }

            .value {
                word-break: break-all;
            }
        }
    }

    .button-container {
        margin-bottom: 0;
        padding-top: 20px;
        text-align: right;

        .action-button {
            padding: 0;
            margin-left: 16px;
        }
    }
}
</style>
