import { App } from 'vue'
import { createStore } from 'vuex'
import getters from '@/store/getters'
import { createStorage } from '@/utils/cache'

const storage = createStorage()

const BASE_KEY = 'BASE'
const HOTEL_ID_KEY = 'HOTEL_ID'

const defaultBase = {
    account: '',
    accountId: null,
    hotelId: null,
    hotelCode: '',
    hotelName: '',
    token: null
}

const store = createStore({
    state: {
        hotelId: storage.get(HOTEL_ID_KEY, null),
        base: storage.get(BASE_KEY, defaultBase)
    },
    getters,
    mutations: {
        SET_BASE(state, value: any) {
            state.base = value
        },
        SET_HOTEL_ID(state, value: any) {
            state.hotelId = value
        }
    },
    actions: {
        setBaseData({ commit }, value: any) {
            commit('SET_BASE', value)
            commit('SET_HOTEL_ID', value.hotelId)
            storage.set(BASE_KEY, value, null)
            storage.set(HOTEL_ID_KEY, value.hotelId, null)
        },
        clearBaseData({ commit }) {
            commit('SET_BASE', defaultBase)
            storage.remove(BASE_KEY)
            storage.remove(HOTEL_ID_KEY)
        }
    }
})

export function setupStore(app: App) {
    app.use(store)
}

export default store
