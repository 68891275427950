import remind from '@/assets/mp3/remind.mp3'

const synth = window.speechSynthesis
const msg = new SpeechSynthesisUtterance()

const zhHans = 'zh-Hans'
const zhCN = 'zh-CN'

let audio

export const speckText = (txt: string) => {
    const voices = window.speechSynthesis.getVoices() || []
    const zhCNVoice = voices.find((c) => c.lang === zhHans || c.lang === zhCN)
    if (zhCNVoice) {
        msg.voice = zhCNVoice
        msg.text = txt
        synth.speak(msg)
    } else {
        if (!audio) {
            audio = new Audio(remind)
        }
        audio.play()
    }
}
