import { createVNode, render, VNodeTypes, getCurrentInstance, App } from 'vue'
import { AsyncComponentLoader } from '@vue/runtime-core'
import { message, Modal } from 'ant-design-vue'
import { v4 as uuid } from 'uuid'
import { importWithRetry } from '@/utils/importWithRetry'

export interface Options {
    destroyAll?: boolean
    onCancel?: (data?: unknown) => void
}

let app: App

export const setApp = (val: App) => {
    app = val
}

const containerMap: Record<string, () => void> = Object.create(null)

export const destroyAll = () => {
    for (const key in containerMap) {
        containerMap[key]()
    }
    Modal.destroyAll()
}

export const useModal = (_Modal: any, props?: Record<string, unknown>, onOk?: (data?: any) => void, options?: Options) => {
    if (!props) {
        props = Object.create(null)
    }
    let container: HTMLDivElement | null = document.createElement('div')
    const id = uuid()
    // 移除组件
    const remove = () => {
        if (container) {
            render(null, container)
            delete containerMap[id]
            container.remove()
            container = null
        }
    }
    containerMap[id] = remove

    // 移除其他弹窗
    if (options && options.destroyAll) {
        for (const key in containerMap) {
            if (key !== id) {
                containerMap[key]()
            }
        }
        // 移除所有modal
        Modal.destroyAll()
    }

    const getContainer = () => {
        return document.getElementById('app')
    }

    const vm = createVNode(_Modal, {
        ...props,
        getContainer,
        remove,
        onOk,
        modalOptions: options || Object.create(null)
    })
    vm.appContext = app._context || getCurrentInstance()?.appContext
    render(vm, container)
    return vm
}

export interface AsyncModalOptions {
    delay?: number
    destroyAll?: boolean
    onCancel?: (data?: unknown) => void
}

const defaultOptions: AsyncModalOptions = {
    delay: 200
}

let delayTimer = 0
export const openAsyncModal = (loader: AsyncComponentLoader, props?: Record<string, unknown>, onOk?: (data?: unknown) => void | Promise<void>, options?: AsyncModalOptions) => {
    window.clearTimeout(delayTimer)
    const realOptions: AsyncModalOptions = Object.assign(Object.create(null), defaultOptions, options)
    let isRemoved = false

    let container: HTMLDivElement | null = document.createElement('div')
    const id = uuid()
    // 移除组件
    const remove = () => {
        if (container) {
            isRemoved = true
            render(null, container)
            delete containerMap[id]
            container.remove()
            container = null
        }
    }
    containerMap[id] = remove

    // 移除其他弹窗
    if (realOptions.destroyAll) {
        for (const key in containerMap) {
            if (key !== id) {
                containerMap[key]()
            }
        }
        // 移除所有modal
        Modal.destroyAll()
    }

    let hide: () => void
    delayTimer = window.setTimeout(() => {
        hide = message.loading('加载中...', 0)
    }, realOptions.delay)

    importWithRetry(loader)
        .then((res) => {
            if (isRemoved) return
            const vm = createVNode(res.default, { ...props, remove, onOk, modalOptions: realOptions })
            vm.appContext = app._context
            render(vm, container!)
        })
        .catch((err) => {
            Modal.error({
                title: '网络连接异常，请刷新后重试！',
                okText: '立即刷新',
                onOk() {
                    window.location.reload()
                }
            })
            throw err
        })
        .finally(() => {
            window.clearTimeout(delayTimer)
            hide?.()
        })
}
